@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.showcase {
    overflow: hidden;
    position: relative;
    right: 0;
    width: 100%;
    min-height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #111;
    transition: 0.5s;
    z-index: 2;
}

.showcase.active {
    right: 300px;
}

.showcase video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
    filter: blur(10px);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.text {
    position: relative;
    z-index: 10;
}

.text h2 {
    font-size: 5em;
    font-weight: 800;
    color: #fff;
    line-height: 1em;
    text-transform: uppercase;
}

.text h3 {
    font-size: 4em;
    font-weight: 200;
    color: #fff;
    line-height: 1em;
    text-transform: uppercase;
}

.text p {
    font-size: 1.1em;
    color: #fff;
    margin: 20px 0;
    font-weight: 400;
    max-width: 700px;
}

.text a {
    display: inline-block;
    font-size: 1em;
    background: #fff;
    padding: 10px 30px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    margin-top: 10px;
    color: #111;
    letter-spacing: 2px;
    transition: 0.2s;
}

.text a:hover {
    letter-spacing: 6px;
}

.social {
    position: absolute;
    z-index: 10;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social li {
    list-style: none;
}

.social li a {
    display: inline-block;
    margin-right: 20px;
    filter: invert(1);
    transform: scale(0.5);
    transition: 0.5s;
}

.social li a:hover {
    transform: scale(0.5) translateY(-15px);
}

.Cursor--blinking {
    animation-name: blinker;
    animation-duration: 1.2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes blinker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

body {
    overflow: hidden;
}

#aboutMe {
    transition: margin-top .2s;
    position: sticky;
    z-index: 2;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

/*noinspection CssInvalidFunction*/
.avatarContainer {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    background-color: rgb(30 30 30 / 86%);
    color: whitesmoke;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(30px);
    border-top: 2px solid #565656d9;
    border-radius: 10px 10px 0 0;
}

.avatarContainer #avatar {
    border: 2px inset;
    border-radius: 50%;
}

.avatarContainer img {
    height: 150px;
}

.avatarContainer h4 {
    text-decoration: underline;
    margin-top: 10px;
    font-weight: 400;
}

.avatarContainer h5 {
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 25px;
    text-decoration: underline;
}

.avatarContainer h6 {
    font-weight: 100;
}

.flex-row {
    display: flex;
    flex-direction: row;
    text-align: center;
}

.flex-row a {
    width: 250px;
    margin-right: 50px;
    text-decoration: none;
    color: whitesmoke;
}

.flex-row button {
    margin-right: 15px;
}

.flex-row a:last-child {
    margin-right: 0;
}

.flex-row button:last-child {
    margin-right: 0;
}

.weight-thin h1 {
    font-weight: 300;
}

.flag {
    height: 15px !important;
}

.transformUp img {
    transform: matrix(1, 0, 0, 1, 0, 0);
    transition: transform 1.5s;
}

.transformUp img:hover {
    transform: matrix(1, 0, 0, 1, 0, -10);
    transition: transform 1.5s;
}